<template>
  <window-content v-if="!preventBind" class="financeiro-window">
    <div class="w-m-header">
      <div>
        <h2>Nota #{{ id }}</h2>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Detalhes do lançamento</legend>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col style="max-width: 100px">
            <erp-s-field label="ID">
              <erp-input disable v-model="nota.id" />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 100px">
            <erp-s-field required label="Número">
              <erp-input v-model="nota.numero" />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 150px">
            <erp-s-field required label="Emissão">
              <erp-input v-model="nota.dataEmissaoManual" :placeholder="$options.filters.formatDate(nota.createdAt)" v-mask="'##/##/####'">
                <u-tooltip :offset="[10,10]">Deixe em branco para o sistema utilizar a data de registro da nota como data de emissão.</u-tooltip>
              </erp-input>
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 200px">
            <erp-s-field required label="Valor">
              <erp-input placeholder="R$" v-model.lazy="nota.valor" v-money="money"/>
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern window-actions">
      <div class="text-left">
        <u-btn @click="$uloc.window.close($root.wid)" label="Sair sem modificar"
               class="bg-blue-grey-1 text-blue" no-caps/>
      </div>
      <div class="text-right">
        <u-btn :loading="loading" type="button" @click="save('andClose')" label="Salvar modificações na Nota" no-caps
               :color="'green'"
               class="b-radius-3px no-border-radius-right"/>
        <u-btn icon="sort-down" icon-type="fa" no-caps class="p-btn-down normal b-radius-3px font-12 text-white">
          <u-popover ref="popover" class="window-context-menu" style="width: 192px" anchor="bottom right"
                     self="top right" :offset="[0, 0]">
            <div>
              <ul>
                <menu-options-item @click="save('andEdit')" label="Salvar e continuar editando" close/>
                <menu-options-item @click="save('andNew')" label="Salvar e criar novo" close/>
              </ul>
            </div>
          </u-popover>
        </u-btn>
      </div>
    </div>
  </window-content>
</template>

<script>
import {UTooltip, UPopover, debounce} from "uloc-vue"
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect, WindowContent} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'

import {find, updateNota} from "@/domain/leiloes/services/notas"
//import CategoriaInput from "@/components/financeiro/components/input/CategoriaInput"
import BancoInput from "@/components/financeiro/components/input/BancoInput"
import DatetimeInput from "@/reuse/input/Datetime"
import mockConta, {mockDetalhe, mockParcela} from "@/domain/financeiro/helpers/mockConta"
import {convertRealToMoney, REAL_BRL} from "@/utils/money"
import {VMoney} from "v-money"
import {REPETIR} from "@/domain/financeiro/helpers/types"
import FinPessoaInput from "@/components/financeiro/components/input/PessoaInput"
import CentroCustoInput from "@/components/financeiro/components/input/CentroCustoInput"
import AnexoContaInput from "@/components/financeiro/components/input/AnexoContaInput"
import globalFinancialMixin from "@/components/financeiro/globalFinancialMixin"
import {datePtToEn} from "@/utils/date"
import FormaPagamentoInput from "@/components/financeiro/components/input/FormaPagamentoInput"
import {findConta} from "@/domain/financeiro/services"
import CategoriaHirerarquiaSelect from "@/components/financeiro/components/input/CategoriaHirerarquiaSelect";
import GlobalProcessoInput from "@/components/processos/components/processos/include/ProcessoInput"
import globalProcessoMixin from "@/components/processos/globalProcessoMixin"
import FinDescricaoInput from "components/financeiro/components/input/DescricaoInput"
import SlCheckbox from "@/reuse/input/Checkbox"
import DetalheValorInput from "components/financeiro/components/input/DetalheValorInput"
import {addMonths, format, parseISO} from "date-fns"
import windowConta from "components/financeiro/windows/conta"

export default {
  name: 'ContaWindowModern',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [],
  directives: {money: VMoney},
  components: {
    // SlCheckbox,
    // DatetimeInput,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    // ErpSelect,
    UTooltip,
    MenuOptionsItem,
    UPopover,
    WindowContent
    // TimerCloseWindow,
    // WindowSuccess,
    // ErpCheckbox
  },
  props: ['id', 'router'],
  data() {
    return {
      money: REAL_BRL,
      loading: false,
      success: false,
      status: '',
      preventBind: true,
      nota: {
        id: null,
        numero: null
      }
    }
  },
  mounted() {
    this.load()
  },
  watch: {},
  computed: {},
  methods: {
    load() {
      this.loading = true
      find(this.id)
          .then(response => {
            console.log(response.data)
            this.preventBind = true
            this.nota = this.prepareToEdit(response.data)
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            // this.task = response.data
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.nota))
      const valores = ['valor']
      valores.map(key => {
        data[key] = convertRealToMoney(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })

      if (data.dataEmissaoManual) {
        data.dataEmissaoManual = datePtToEn(data.dataEmissaoManual)
      }
      return data
    },
    prepareToEdit(data) {
      const valores = ['valor']
      valores.map(key => {
        data[key] = this.$options.filters.moeda(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })
      const datas = ['dataEmissaoManual']
      datas.map(key => {
        if (data[key] && data[key].date) {
          data[key] = this.$options.filters.formatDate(data[key].date)
        }
      })
      return data
    },
    async save(type = 'andClose') {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      const submit = () => {

        updateNota(this.id, data)
            .then(response => {
              this.loading = false
              console.log(response)
              const isEditing = this.isEditing
              this.nota.id = response.data.id
              // this.$uloc.window.close(this.$root.wid)
              this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
              this.success = true
              this.dg()
            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.alertApiError(error)
            })
      }
      submit()
    }
  }
}
</script>
